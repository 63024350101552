import React from "react";

import Logo from "./Logo";
import LogoNoBeta from "./Logo-no-beta";
import Comments from "./Comments";
import Connectedness from "./Connectedness";
import Discussion from "./Discussion";

const Icon = props => {
  switch (props.name) {
    case "logo":
      return <Logo {...props} />;
    case "logo-no-beta":
      return <LogoNoBeta {...props} />;
    case "comments":
      return <Comments {...props} />;
    case "connectedness":
      return <Connectedness {...props} />;
    case "discussion":
      return <Discussion {...props} />;
    default:
      return;
  }
};

export default Icon;
