import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';

import Dropdown from '../dropdown';
import DropdownItem from '../dropdown/dropdown-item';
import PaypalButton from '../../etc/paypal-button';

import { pages } from '../../../config/pagesConfig';
import styles from './toolbar.module.scss';
import OnlyClassroom from '../only-classroom';
import OnlyCore from '../only-core';

const NavMenu = (props) => (
  <Dropdown menuStyle={{ left: 33 }}>
    {pages.map((link, index) => <DropdownItem key={`navitem${index}`} linkTo={link.path}>{link.title}</DropdownItem>)}
    <OnlyClassroom>
      <DropdownItem linkTo={props.environmentSwitchUrl} externalLink>Public Fiskkit <FontAwesomeIcon icon="external-link-alt" /></DropdownItem>
    </OnlyClassroom>
    <OnlyCore>
      <DropdownItem linkTo={props.environmentSwitchUrl} externalLink>Fiskkit Classroom <FontAwesomeIcon icon="external-link-alt" /></DropdownItem>
    </OnlyCore>
    <div className={styles.fromTablet}><PaypalButton style={{ width: '100%' }}><DropdownItem>Support Us</DropdownItem></PaypalButton></div>
  </Dropdown>
)

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  return {
    environmentSwitchUrl: _.get(app, 'ENVIRONMENT_SWITCH_URL')
  };
};

export default connect(mapStateToProps)(NavMenu);
