import React from 'react';

import styles from './dropdown.module.scss';

export interface DropdownIndicatorProps {
  active: boolean;
  lightBackground?: boolean;
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return <div className={props.active ? styles.sideTriangleRed : props.lightBackground ? styles.sideTriangleBlack : styles.sideTriangle} />;
}

export default DropdownIndicator