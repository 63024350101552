import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import OnlyClassroom from '../only-classroom';
import UserListItem from '../../pages/discuss/user-list-item';
import Dropdown from '../dropdown';
import DropdownItem from '../dropdown/dropdown-item';

import styles from './toolbar.module.scss';


const IsoMenu = (props) => {
  const { discuss, user } = props
  let contributors = [...props.contributors];

  const isoCourse = _.find(discuss.courses, { id: discuss.iso.courseId });
  
  if (isoCourse) {
    // filter contributors by class
    const courseUsers = _.map(isoCourse.users, 'id');
    contributors = _.filter(contributors, contributor=>courseUsers.includes(contributor.id));
  }

  let currentUser = _.first(_.remove(contributors, { 'id': user.id })); // get current user from contributor list

  // Display current user's name as 'Me'
  if (user.id) {
    if (!currentUser) { // if current user is not a contributor
      currentUser = Object.assign({}, user);
    }
    currentUser['name'] = 'Me';
  }

  const isoUser = _.find(
    [...contributors, currentUser],
    {id: _.get(discuss, 'iso.userId')}
  );

  const isoButton = (<span
    style={{display: 'inline-flex', alignItems: 'center'}}
    className={isoUser ? styles.featureLinkActive : styles.featureLink}
  >
    <span>{isoUser ? "ISO" : isoCourse ? `ISO ${isoCourse.name}` : "ISO Filter"}</span>
    {isoUser ? (
      <span
        style={{
          display: "inline-block",
          marginLeft: "0.8em",
          color: "white"
        }}
      >
        {" "}
        <UserListItem user={isoUser} isOnToolbarDisplay={true} />
      </span>
    ) : null}
  </span>)

  return (
    <Dropdown containerClassName={styles.featureItemStyle} menuClassName={styles.dropdownMenu} label={isoButton} arrow={false}>
      {isoUser || isoCourse ? (
        <Link
          style={{ textDecoration: "none" }}
          className="dropdown-item"
          to={`/article/${discuss.article.id}/discuss`}
        >
          Show All Fisks
        </Link>
      ) : null}
      <OnlyClassroom>
        {discuss.courses.map(course=>(
            <DropdownItem key={`isoitem${course.id}`} linkTo={`/article/${discuss.article.id}/discuss?course=${course.id}`}>
              <span className="dropdown-item">{course.name}</span>
            </DropdownItem>
          )
        )}
      </OnlyClassroom>
      {currentUser ? <Link
          className="dropdown-item"
          to={"/article/" + discuss.article.id + "/iso/" + currentUser['id']}
        >
          <UserListItem user={currentUser} />
      </Link> : null}
      {contributors.map(user => (
        <Link
          key={user.id}
          className="dropdown-item"
          to={"/article/" + discuss.article.id + "/iso/" + user.id}
        >
          <UserListItem user={user} />
        </Link>
      ))}
      {contributors.length === 0 && !currentUser ? (
        <span className="dropdown-item">
          There are no contributors for this Article at this time
        </span>
      ) : null}
    </Dropdown>
  )

  // OLD ISO MENU NOT USING DROPDOWN COMPONENTS

  // return (
  //   <div className={styles.featureItemStyle + " dropdown " + (discussDropdownActive ? 'is-active' : null)}>
  //     <div className="dropdown-trigger" style={{display: 'flex'}}>
  //       <span
  //         onClick={() => activateDropdown(!discussDropdownActive)}
  //         style={{display: 'inline-flex', alignItems: 'center'}}
  //         className={isoUser ? styles.featureLinkActive : styles.featureLink}
  //         aria-haspopup="true"
  //         aria-controls="dropdown-menu"
  //       >
  //         <span>{isoUser ? "ISO" : "ISO Filter"}</span>
  //         {isoUser ? (
  //           <span
  //             style={{
  //               display: "inline-block",
  //               marginLeft: "0.8em",
  //               color: "white"
  //             }}
  //           >
  //             {" "}
  //             <UserListItem user={isoUser} isOnToolbarDisplay={true} />
  //           </span>
  //         ) : null}
  //       </span>
  //     </div>
  //     <div
  //       className={`dropdown-menu ${styles.dropdownMenu}`}
  //       id="dropdown-menu"
  //       role="menu"
  //       onClick={() => activateDropdown(!discussDropdownActive)}
  //     >
  //       <div
  //         className="dropdown-content"
  //         style={{ maxHeight: "80vh", overflowY: "auto" }}
  //       >
  //         {isoUser || courseId ? (
  //           <Link
  //             style={{ textDecoration: "none" }}
  //             className="dropdown-item"
  //             to={`/article/${discuss.article.id}/discuss`}
  //           >
  //             Show All Fisks
  //           </Link>
  //         ) : null}
  //         {currentUser ? <Link
  //             className="dropdown-item"
  //             to={"/article/" + discuss.article.id + "/iso/" + currentUser['id']}
  //           >
  //             <UserListItem user={currentUser} />
  //         </Link> : null}
  //         <OnlyClassroom>
  //           <DropdownItem><span className="dropdown-item">Courses</span></DropdownItem>
  //           {discuss.courses.filter(course=>user.courses.map(course=>course.id).includes(course.id)).map(course=>(
  //               <DropdownItem key={`isoitem${course.id}`} linkTo={`/article/${discuss.article.id}/discuss?course=${course.id}`}>
  //                 <span className="dropdown-item">{course.name}</span>
  //               </DropdownItem>
  //             )
  //           )}
  //         </OnlyClassroom>

  //         {contributors.map(user => (
  //           <Link
  //             key={user.id}
  //             className="dropdown-item"
  //             to={"/article/" + discuss.article.id + "/iso/" + user.id}
  //           >
  //             <UserListItem user={user} />
  //           </Link>
  //         ))}
  //         {contributors.length === 0 ? (
  //           <span className="dropdown-item">
  //             There are no contributors for this Article at this time
  //           </span>
  //         ) : null}
  //       </div>
  //     </div>
  //   </div>
  // );
}


const mapStateToProps = (state) => {
const { auth, discuss, user, loading } = state;
return {
  auth,
  user,
  discuss,
  loading,
  contributors: _.get(discuss, 'contributors', [])
};
};


export default connect(mapStateToProps)(withRouter(IsoMenu));