import {
  FETCH_COURSE_INSIGHTS_RECEIVED,
  FETCH_COURSE_INSIGHTS_REQUESTED
} from '../actions/insights'

export const groupInsights = (state = {}, action) => {
  const { type, payload } = action

  switch (type){
    case FETCH_COURSE_INSIGHTS_RECEIVED:
      return Object.assign(state, payload)
    case FETCH_COURSE_INSIGHTS_REQUESTED:
      return {}
    default:
      return state
  }
}