const userVoiceWidget = (user) => {
  const UserVoice = window.UserVoice;

  // Set colors
  UserVoice.push(
    ['set',
      {
        accent_color: '#448dd6',
        trigger_color: 'white',
        trigger_background_color: 'rgba(46, 49, 51, 0.6)'
      }
    ]
  );

  // Identify the user and pass traits
  // To enable, replace sample data with actual user traits and uncomment the line

  UserVoice.push(['identify', {
    email:      user.email, // User’s email address
    name:       user.name, // User’s real name
    created_at: user.created_at, // Unix timestamp for the date the user signed up
    id:         user.id, // Optional: Unique id of the user (if set, this should not change)
    type:       user.instructor ? 'Instructor' : 'User', // Optional: segment your users by type
    //account: { // Account traits are only available on some plans
    //  id:           123, // Optional: associate multiple users with a single account
    //  name:         'Acme, Co.', // Account name
    //  created_at:   1364406966, // Unix timestamp for the date the account was created
    //  revenue:      9.99, // Decimal; recurring revenue of the account
    //  ltv:          1495.00, // Decimal; lifetime value of the account
    //  plan:         'Enhanced' // Plan name for the account
    //}
  }]);

  // Add default trigger to the bottom-right corner of the window:
  UserVoice.push(['addTrigger', {mode: 'contact', trigger_position: 'bottom-right' }]);


  // Or, use your own custom trigger:
  //UserVoice.push(['addTrigger', '#id', { mode: 'contact' }]);

  // Autoprompt for NPS® Rating and SmartVote (only displayed under certain conditions)
  // UserVoice.push(['autoprompt', {}]);
}

export default userVoiceWidget;
