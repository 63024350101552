import _ from 'lodash';

import {
  DISCUSS_COLUMN_OPEN,
  SET_ACTIVE_VALUES,
  SET_ISO_MODE,
  FETCH_ARTICLE_REQUESTED,
  FETCH_ARTICLE_RECEIVED,
  // FETCH_ARTICLE_FAILED,
  CREATE_ARTICLE_COURSES_REQUESTED,
  CREATE_ARTICLE_COURSES_RECEIVED,
  // CREATE_ARTICLE_COURSES_FAILED,
  DELETE_ARTICLE_COURSES_REQUESTED,
  DELETE_ARTICLE_COURSES_RECEIVED,
  // DELETE_ARTICLE_COURSES_FAILED,
  FETCH_ARTICLE_FISKS_REQUESTED,
  FETCH_ARTICLE_FISKS_RECEIVED,
  // FETCH_ARTICLE_FISKS_FAILED,
  FETCH_ARTICLE_INSIGHTS_REQUESTED,
  FETCH_ARTICLE_INSIGHTS_RECEIVED,
  // FETCH_ARTICLE_INSIGHTS_FAILED,
  FETCH_FISK_RECEIVED,
  FETCH_FISK_REQUESTED,
  // FETCH_FISK_FAILED,
  FETCH_FISK_CHILDREN_REQUESTED,
  FETCH_FISK_CHILDREN_RECEIVED,
  // FETCH_FISK_CHILDREN_FAILED,
  CREATE_FISK_REQUESTED,
  // DELETE_FISK_REQUESTED,
  UPDATE_FISK_REQUESTED,
  CREATE_FISK_RECEIVED,
  DELETE_FISK_RECEIVED,
  UPDATE_FISK_RECEIVED,
  CREATE_FISK_FAILED,
  DELETE_FISK_FAILED,
  UPDATE_FISK_FAILED,
  FETCH_ARTICLE_COURSES_REQUESTED,
  FETCH_ARTICLE_COURSES_RECEIVED,
  // FETCH_ARTICLE_COURSES_FAILED,
  CREATE_FISK_RESPECT_RECEIVED,
  DELETE_FISK_RESPECT_RECEIVED,
  FETCH_ARTICLE_CONTRIBUTORS_REQUESTED,
  FETCH_ARTICLE_CONTRIBUTORS_RECEIVED,
  UPDATE_COURSE_ARTICLE_BLINDERS_REQUESTED,
  UPDATE_COURSE_ARTICLE_BLINDERS_RECEIVED,
  UPDATE_COURSE_ARTICLE_AUTHOR_REQUESTED,
  UPDATE_COURSE_ARTICLE_AUTHOR_RECEIVED,
  UPDATE_COURSE_ARTICLE_PUBLISHER_REQUESTED,
  UPDATE_COURSE_ARTICLE_PUBLISHER_RECEIVED,
  UPDATE_COURSE_ARTICLE_TEACHERS_FISK_REQUESTED,
  UPDATE_COURSE_ARTICLE_TEACHERS_FISK_RECEIVED,
  LOGOUT,
  OPTIMISTICALLY_SAVE_FISK,
  OPTIMISTIC_FISK_CLEAR,
  SAVE_FISK_DRAFT,
  SENTENCE_CLOSE_ALL,
  SENTENCE_OPEN_ALL,
  SENTENCE_IS_OPEN
} from '../actions';

export const initialState = {
  active: {
    sentence_id: null,
    column_level: 1,
  },
  article: {
    author: null,
    created_at: null,
    paragraphs: [],
    publisher: null,
    title: 'Loading Article',
  },
  columns: [],
  courses: [],
  contributors: [],
  fiskDraft: JSON.parse(localStorage.getItem("draft")) || null,
  fisks: [],
  insights: [],
  iso: {
    userId: 0,
    courseId: 0,
  },
  openSentences: {
    all: false,
  },
  optimisticFisk: {
    save_type: null,
    fisk: {} // TODO: Use draft instead
  },

};

function handleColumnChange(columns, payload) {
  _.remove(columns, c => c.level >= payload.level); // remove recursive columns higher than current level
  if (payload.open) {
    return [...columns, payload]
  }
  return columns;
}


export const discuss = (state = initialState, { payload, type }) => {
    switch (type) {

      case LOGOUT: return initialState;

      case DISCUSS_COLUMN_OPEN:
        return _.assignIn({}, state, { columns: handleColumnChange(state.columns, payload) });

      case SET_ACTIVE_VALUES:
        return _.assignIn({}, state, payload);
      case SET_ISO_MODE:
        return _.assignIn({}, state, {iso: { userId: payload.userId, courseId: payload.courseId }});

      case FETCH_ARTICLE_REQUESTED:
        return _.assignIn({}, initialState);
      case FETCH_ARTICLE_RECEIVED:
        return _.assignIn({}, state, payload);

      case CREATE_FISK_RESPECT_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy([payload.fisk], state.fisks, "id") });

      case DELETE_FISK_RESPECT_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy([payload.fisk], state.fisks, "id") });

      case CREATE_ARTICLE_COURSES_REQUESTED:
        return _.assignIn({}, state);
      case CREATE_ARTICLE_COURSES_RECEIVED:
        return _.assignIn({}, state, { courses: payload.courses });

      case DELETE_ARTICLE_COURSES_REQUESTED:
        return _.assignIn({}, state);
      case DELETE_ARTICLE_COURSES_RECEIVED:
        return _.assignIn({}, state, { courses: payload.courses });

      case FETCH_ARTICLE_COURSES_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_ARTICLE_COURSES_RECEIVED:
        return _.assignIn({}, state, { courses: payload.courses });

      case FETCH_ARTICLE_INSIGHTS_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_ARTICLE_INSIGHTS_RECEIVED:
        return _.assignIn({}, state, { insights: payload });


      case UPDATE_COURSE_ARTICLE_BLINDERS_REQUESTED:
      case UPDATE_COURSE_ARTICLE_AUTHOR_REQUESTED:
      case UPDATE_COURSE_ARTICLE_PUBLISHER_REQUESTED:
      case UPDATE_COURSE_ARTICLE_TEACHERS_FISK_REQUESTED:
        return _.assignIn({}, state);
      case UPDATE_COURSE_ARTICLE_BLINDERS_RECEIVED:
      case UPDATE_COURSE_ARTICLE_AUTHOR_RECEIVED:
      case UPDATE_COURSE_ARTICLE_PUBLISHER_RECEIVED:
      case UPDATE_COURSE_ARTICLE_TEACHERS_FISK_RECEIVED:
        return _.assignIn({}, state, { courses: _.unionBy([payload.course], state.courses, "id") });

      case FETCH_ARTICLE_CONTRIBUTORS_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_ARTICLE_CONTRIBUTORS_RECEIVED:
        return _.assignIn({}, state, { contributors: payload.contributors });

      case FETCH_ARTICLE_FISKS_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_ARTICLE_FISKS_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy(payload.data, state.fisks, "id")});

      case FETCH_FISK_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_FISK_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy([payload], state.fisks, "id")});

      case FETCH_FISK_CHILDREN_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_FISK_CHILDREN_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy(payload.data, state.fisks, "id")});

      case CREATE_FISK_REQUESTED:
      case UPDATE_FISK_REQUESTED:
        return _.assignIn({}, state);
      case CREATE_FISK_RECEIVED:
      case UPDATE_FISK_RECEIVED:
        return _.assignIn({}, state, { fisks: _.unionBy([payload], state.fisks, "id") });
      case DELETE_FISK_RECEIVED:
        return _.assignIn({}, state, { fisks: _.filter(state.fisks, (fisk)=>fisk.id!==payload.fisk.id) });
      case CREATE_FISK_FAILED:
      case UPDATE_FISK_FAILED:
      case DELETE_FISK_FAILED:
        return _.assignIn({}, state, { error: payload, fisks: {optimisticFisk: initialState.optimisticFisk} }); // need to change this to set sentence active again

      case OPTIMISTICALLY_SAVE_FISK:
        return _.assignIn({}, state, { optimisticFisk: payload });
      case OPTIMISTIC_FISK_CLEAR:
        return _.assignIn({}, state, { optimisticFisk: initialState.optimisticFisk });

      case SAVE_FISK_DRAFT:
        return _.assignIn({}, state, { fiskDraft: payload });

      case SENTENCE_OPEN_ALL:
        return _.assignIn({}, state, { openSentences: _.assignIn({}, state.openSentences, {all: true})});
      case SENTENCE_CLOSE_ALL:
        return _.assignIn({}, state, { openSentences: initialState.openSentences });
      case SENTENCE_IS_OPEN:
        const openSentencesState = {...state.openSentences};
        openSentencesState[payload.sentenceId] = payload.isOpen;
        return _.assignIn({}, state, {openSentences: openSentencesState});

      default:
        return state;
    }
  };
