// Used to track events. Events are noticed through redux actions
import ReactGA from 'react-ga';

export const googleAnalyticsMiddleware = store => next => action => {
  switch(action.type) {
    case 'OPEN_MODAL':
      if (action.payload.id === 'MODAL_WHAT_IS_FISKKIT') {
        ReactGA.event({
          category: 'user',
          action: "opens 'What is Fiskkit?' modal"
        });
      }
      break;
    default:
      // nothing
  }
  return next(action);
};
