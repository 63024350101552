import * as config from '../config/app';

export default function (hostname: string): {} {
  let environment = 'local';

  switch (hostname) {
    // Prod
    case 'frontendreact-production.us-west-1.elasticbeanstalk.com':
    case 'www.fiskkit.com':
    case 'fiskkit.com':
      environment = 'core';
      break;
    case 'classroom.fiskkit.com':
      environment = 'classroom';
      break;

    // Dev
    case 'devclassroom.fiskkit.com':
      environment = 'classroom_development';
      break;
    case 'dev.fiskkit.com':
      environment = 'core_development';
      break;

    // beta
    case 'corebetaserver.fiskkit.com':
      environment = 'core_beta';
      break;
    case 'classroombetaserver.fiskkit.com':
      environment = 'classroom_beta';
      break;

    // Local
    case 'ff.local:3000':
      environment = 'classroom_local';
      break;

    default:
      environment = 'local';
      break;
  }

  return {
    app: config[environment],
  };
}
