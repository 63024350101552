import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import _ from 'lodash';

import App from './app';
import environment from './utils/environment';
import { loadState } from './utils/helpers';
import { googleAnalyticsMiddleware } from './utils/googleAnalyticsMiddleware';
import http from "./middleware/http";
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

import 'react-redux-notify/dist/ReactReduxNotify.css';
import './resources/css/style.scss';

// Set up Icons globally!
import { library as IconLibrary } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSpinner, faExclamationCircle, faCheckCircle, faChevronLeft,
  faMinus, faPlus, faCogs, faCommentMedical, faCommentSlash,
  faComments, faPenAlt, faFont, faExclamationTriangle, faHeart,
  faExternalLinkAlt, faAngleDoubleUp
} from '@fortawesome/free-solid-svg-icons';

IconLibrary.add(faSpinner, faExclamationCircle, faCheckCircle, faChevronLeft, faMinus,
  faPlus, faCogs, faCommentMedical, faCommentSlash, faComments, faPenAlt, faFont,
  faExclamationTriangle, faHeart, faExternalLinkAlt, faAngleDoubleUp, fab
);

const initialState = Object.assign({}, environment(window.location.host), loadState())
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // for Redux Dev Tools
const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(thunk, http, googleAnalyticsMiddleware))
);

// Used for Login popup
// When user gets a token back from backend must send it to opener window.
if (_.get(window.opener, 'origin') === window.origin) {

  ReactDOM.render(
    <p>...logging you in.</p>,
    document.getElementById('root')
  );

  setTimeout(()=> {
    window.close();
  }, 1000);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token"); // get token from url

  if (token) {
    window.opener.postMessage({ token }, window.origin);
  } else {
    window.opener.postMessage({error: 'could not log you in'}, window.origin);
  }
} else {
  ReactDOM.render(
    <Provider store={store}><App /></Provider>,
    document.getElementById('root')
  );
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
