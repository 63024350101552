export const TAGS = [
    {id:  1, color: '#7857cf', name: "True",              description: "This sentence is factually correct. Usually only applied if the facts are controversial."},
    {id:  2, color: '#7857cf', name: "False",             description: "At least one element of this sentence is factually incorrect."},
    {id:  3, color: '#7857cf', name: "Matter of Opinion", description: "This sentence is not a question of fact. The answer is either unknowable, a normative (“we should…”)  or there is no accepted standard to measure one answer v. another."},
    {id:  4, color: '#CA5339', name: "Overgeneralization",description: "Author either writes in vague terms (e.g. “experts say…”) or draws an unwarranted general conclusion from a small or vague set of evidence."},
    {id:  5, color: '#CA5339', name: "Cherry Picking",    description: "Pointing to specific cases or data that seem to confirm a particular position, while ignoring a significant portion of related cases or data that may contradict that position."},
    {id:  6, color: '#CA5339', name: "Straw Man",         description: "An incomplete or inaccurate representation of an opposing argument that is easier to refute or defeat, creating the illusion of winning the argument."},
    {id:  7, color: '#00C87B', name: "Insightful",        description: "A non-obvious observation of some value."},
    {id:  8, color: '#00C87B', name: "Well Researched",   description: "Author cited evidence for an assertion or otherwise “did their homework” to back up a claim. Simply linking to an outside page does not make something Well Researched."},
    {id:  9, color: '#00C87B', name: "Funny",             description: "Really? You don’t know what “funny” means?"},
    {id: 10, color: '#3078A7', name: "Unsupported",       description: "Author has made a controversial claim but has not cited reasoning or evidence to back it up."},
    {id: 11, color: '#3078A7', name: "Overly Simplistic", description: "Author has reduced an issue to so much less complexity and nuance that it no longer faithfully represents the issue at hand."},
    {id: 12, color: '#3078A7', name: "Biased Wording",    description: "Use of incendiary, “loaded” or other language meant to persuade the reader, rather than neutrally inform them."},
    {id: 13, color: '#7857cf', name: "Provably True",     description: "A correct statement of objective fact, for which you have evidence. This tag requires you to cite a valid, supporting link in the comment."},
    {id: 14, color: '#7857cf', name: "Provably False",    description: "A false statement of objective fact, for which you have evidence. This tag requires you to cite a valid, supporting link in the comment."},
    {id: 15, color: '#3078A7', name: "Personal Attack",   description: "An abusive remark on or relating to any person instead of providing evidence when examining another person's claims or comments. Not personal attacks: saying someone is wrong or criticizing them."},
    {id: 16, color: '#3078A7', name: "Off-Topic",         description: "Not directly relevant to the subject of this discussion. Includes 'whatabouts' that seek to redirect away from the original topic."},
    {id: 17, color: '#00C87B', name: "Concession",        description: "The commenter acknowledges and concedes a point another commenter has made which does not support their argument. Give props for good-faith!"}
  ] 
