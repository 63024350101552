import React from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OnlyClassroom from '../only-classroom';
import IsoMenu from './iso-menu';
import NavMenu from './nav-menu';
import UserMenu from './user-menu';
import PaypalButton from '../../etc/paypal-button';
import Icon from '../../../icon';

import { fetchCurrentUser, openModal } from '../../../actions';
import { MODAL_TOOLBAR_OPTIONS } from '../../../constants';

import * as styles from './toolbar.module.scss';
import * as layoutStyles from '../layout.module.scss';
import * as screenBreakpointVars from '../../../resources/css/sass/_variables.scss';


class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.refToToolbar = React.createRef();
    this.lastTouchClientY = null;
  }


  componentDidMount() {
    this.handleLoadingUser(_.get(this.props, 'auth.access_token'));
    window.addEventListener('touchmove', this.handleHidingToolbar);
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props.auth, 'access_token') !== _.get(prevProps.auth, 'access_token')) {
      this.handleLoadingUser(_.get(this.props.auth, 'access_token'))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleHidingToolbar);
  }

  handleHidingToolbar = (e) => {
    if (window.innerWidth <= parseInt(screenBreakpointVars.bpLarge)) {
      const currentTouchClientY = e.touches[0].clientY;
      const toolbar = this.refToToolbar.current; 
      let layoutMainClass = document.getElementsByClassName(layoutStyles.mainClass)[0];

      if (currentTouchClientY > this.lastTouchClientY) {
        toolbar.style.top = "0";
        layoutMainClass.style.paddingTop = "60px";
      } else if (currentTouchClientY < this.lastTouchClientY) {
        toolbar.style.top = "-60px";
        layoutMainClass.style.paddingTop = "0px";
      }

      this.lastTouchClientY = currentTouchClientY;
    }
  }

  handleLoadingUser = (access_token) => {
    const { user, loading, fetchCurrentUser } = this.props;

    if (access_token && !_.get(user, 'id') && !_.get(loading, 'FETCH_CURRENT_USER')) {
      fetchCurrentUser();
    }
  }

  render() {
    const { discuss, user } = this.props;

    return (
        <div className={styles.toolbarContainer} ref={this.refToToolbar}>
          <div className={styles.toolbarLeftSideMenu}>
            <div className={styles.logoWrapper}>
              <Link to='/'>
                <Icon name="logo" width={100} height={40} fill="white" style={{ display: 'block' }}/>
              </Link>
            </div>
            <NavMenu />
          </div>
          <Route path={"/article/:article_id"} render={({ location })=>{
              const urlArr = location.pathname.split('/');
              const onInsight = urlArr[1] === 'article' && urlArr[urlArr.length - 1] === 'insights';

              return discuss.article.id ? 
                <div className={styles.featuresContainer}>
                  <ul className={styles.featureItemsList}>
                    <li className={`${styles.featureItemStyle} ${styles.onlyMobile}`}>
                      <span onClick={() => this.props.openModal(MODAL_TOOLBAR_OPTIONS)} className={styles.featureLink}>Options</span>
                    </li>
                    <IsoMenu />
                    <li className={`${styles.featureItemStyle}`}>
                      <Link style={{ textDecoration: 'none' }} className={styles.featureLink} to={`/article/${discuss.article.id}/` + (onInsight ? 'discuss' : 'insights')}>
                        <span className={styles.featureLink}>{onInsight ? 'Discuss' : 'Insight'}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              : null;
            }} />

        <div className={styles.toolbarRightSideMenu}>
          <PaypalButton style={{ marginRight: 10 }}>
            <span className={`${styles.toolbarLink} ${styles.onlyDesktop}`}><FontAwesomeIcon icon="heart" /> Support Fiskkit</span>
          </PaypalButton>
          <UserMenu user={user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { auth, discuss, user, loading } = state;
  return {
    auth,
    user,
    discuss,
    loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    openModal: (id) => dispatch(openModal(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
