import React from "react";

const Logo = ({
  style = { margin: " -40 -30px -10px 0" },
  fill = "#fff",
  width = "50%",
  height = width,
  className = "",
  viewBox = "0 0 186.35 64.77",
  onClick,
}) => (
      <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path fill={fill} d="M123.35,0V29h.13L135,14.27h11L134.37,29,146.5,44H135.05L123.47,29.36h-.13V44H114V0Zm63,14.27v6.39H175.51V32.92a4.84,4.84,0,0,0,1.21,3.48,4.41,4.41,0,0,0,3.35,1.28,13.83,13.83,0,0,0,6.27-1.73v6.77a22.54,22.54,0,0,1-8.66,1.86q-5.48,0-8.5-2.83t-3-8.32V20.66H161.9V18.83L174.34,4.91h1.17v9.36Z" />
        <path fill={fill} d="M153.81,6.47l-4.63,7.46V44h9.25V13.93ZM150,13.93l2.83-4.53h1.89l2.83,4.53Z" />
        <path fill={fill} d="M30.3,0V8.06H11v9.83H30.3V26H11V44H0V0ZM72.07,15.84v6.65q-5.35-3.28-9.92-3.28-3.37,0-3.37,2.3a1.59,1.59,0,0,0,.71,1.18,32.75,32.75,0,0,0,5.43,2.46Q69.64,27,71.6,29.5a8.48,8.48,0,0,1,2,5.39A8.64,8.64,0,0,1,70.4,42q-3.15,2.52-9,2.52A24.92,24.92,0,0,1,50.78,42.4V35.81a25,25,0,0,0,9.61,2.52q4.54,0,4.54-2.2a2.17,2.17,0,0,0-.84-1.6q-.84-.75-5.62-2.62T51.91,27.8a8.07,8.07,0,0,1,1.62-11.6,14.45,14.45,0,0,1,8.93-2.55A20.69,20.69,0,0,1,72.07,15.84ZM87.76,0V29h.13L99.43,14.27h11L98.78,29l12.13,15H99.46L87.88,29.36h-.13V44H78.4V0Z" />
        <path fill={fill} d="M35.72,13.64V43.71l4.63,7.46L45,43.71V13.64Zm5.57,34.61H39.41l-2.83-4.53h7.54Z" />
        <path fill={fill} d="M138,52.36h3.5a6.62,6.62,0,0,1,3.57.74,2.61,2.61,0,0,1,1.1,2.33,2.69,2.69,0,0,1-.61,1.82,3,3,0,0,1-1.79.93v.08q2.83.48,2.83,3a3.19,3.19,0,0,1-1.12,2.6,4.79,4.79,0,0,1-3.14.93H138Zm1.44,5.31h2.38a3.86,3.86,0,0,0,2.2-.48,1.85,1.85,0,0,0,.67-1.62,1.63,1.63,0,0,0-.75-1.51,4.66,4.66,0,0,0-2.38-.46h-2.12Zm0,1.22v4.64H142a3.67,3.67,0,0,0,2.26-.58,2.14,2.14,0,0,0,.76-1.82,1.93,1.93,0,0,0-.78-1.7,4.15,4.15,0,0,0-2.36-.54Z" />
        <path fill={fill} d="M158.67,64.77h-6.92V52.36h6.92v1.28H153.2v4h5.14v1.27H153.2v4.56h5.47Z" />
        <path fill={fill} d="M168.75,64.77H167.3V53.65h-3.93V52.36h9.3v1.28h-3.93Z" />
        <path fill={fill} d="M184.85,64.77l-1.54-3.94h-5l-1.53,3.94h-1.46l4.9-12.45h1.21l4.88,12.45Zm-2-5.24-1.44-3.84q-.28-.73-.58-1.79a16.53,16.53,0,0,1-.54,1.79l-1.46,3.84Z" />
      </svg>
  );

export default Logo;
