import React from 'react';
import styles from './style.module.scss'

const OpEdBanner = () => (
  <div className={styles.opEdBanner}>
    <span>
      <a
        style={{ color: 'white', textDecoration: 'underline' }}
        href="https://oped.fiskkit.com/signup">
        Join the Op-Ed Challenge
      </a>
      &nbsp;to receive 1 fiskable article per day!
    </span>
  </div>
)

export default OpEdBanner;