const facebookInit = (APPID) => {
  const { FB } = window;

  FB.init({
    appId: APPID,
    status: true,
    version: 'v5.0',
  });

  FB.AppEvents.logPageView();
}

export default facebookInit;
